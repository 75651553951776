/*
 * @Date: 2022-09-24 15:03:29
 * @LastEditTime: 2022-09-27 10:09:54
 * @FilePath: \mergeT\frontend\src\client.ts
 */
import { HttpClient } from 'tsrpc-browser';
import { EncryptUtil } from './shared/models/EncryptUtil';
import { serviceProto } from './shared/protocols/serviceProto';

// Create Client
export const client = new HttpClient(serviceProto, {
    server: 'http://13.251.239.41:3002',
    // Remove this to use binary mode (remove from the server too)
    json: true,
    logger: console,
});

// 发送前加密
client.flows.preSendDataFlow.push((v) => {
    if (v.data instanceof Uint8Array) {
        v.data = EncryptUtil.encrypt(v.data);
    }
    return v;
});
// 接收前解密
client.flows.preRecvDataFlow.push((v) => {
    if (v.data instanceof Uint8Array) {
        v.data = EncryptUtil.decrypt(v.data);
    }
    return v;
});
