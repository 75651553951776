
import { defineComponent } from "vue";
import { client } from "./client";
import { ResGetData } from "./shared/protocols/PtlGetData";

export interface AppData {
  input: string;
  list: ResGetData["data"];
}

export default defineComponent({
  name: "App",
  data() {
    return {
      input: "",
      list: [],
    } as AppData;
  },

  methods: {
    async loadList() {
      let ret = await client.callApi("GetData", {});

      // Error
      if (!ret.isSucc) {
        alert(ret.err.message);
        return;
      }

      // Success
      this.list = ret.res.data;
    },

    async send() {
      let ret = await client.callApi("user/DelUser", {
        id:this.input,
      });
      // let ret = await client.callApi("AddData", {
      //   content: this.input,
      // });

      // Error
      if (!ret.isSucc) {
        alert(ret.err.message);
        return;
      }

      // Success
      this.input = "";
      this.loadList();
    },
  },

  mounted() {
    this.loadList();
  },
});
