/*
 * @Date: 2022-09-24 15:08:11
 * @LastEditTime: 2022-09-24 15:08:15
 * @FilePath: \mergeT\backend\src\shared\models\EncryptUtil.ts
 */
export class EncryptUtil {

    // 加密
    static encrypt(buf: Uint8Array): Uint8Array {
        for (let i = 0; i < buf.length; ++i) {
            buf[i] -= 1;
        }
        return buf;
    }

    // 解密
    static decrypt(buf: Uint8Array): Uint8Array {
        for (let i = 0; i < buf.length; ++i) {
            buf[i] += 1;
        }
        return buf;
    }

}